import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import Select from '@mui/material/Select'
import Theme from '../Styles/Theme'
import requests from '../AxiosCalls/AxiosCall'
import StyleGeneral from '../Styles/StyleGeneral'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'

const UsuariosAdd = (props) => {
	const classes = StyleGeneral()
	const methods = useForm()
	const { handleSubmit } = methods

	const [_Registro, SetRegistro] = useState({
		txtNombre: '',
		txtApellido: '',
		txtNomCorto: '',
		txtPassword: '',
		numTelefono1: '',
		txtEmail: '',
		bolBloqueado: false,
		intIdArea: '',
		intIdSubarea: null,
		intIdEmpresa: null,
		intIdPlaza: '',
		intIdDepartamentos: '',
		intIdRegion: null,
		intIdPais: '',
	})

	const handleClose = () => {
		props.onClose()
		props.getRegistros()
		LimpiarValores()
	}
	const LimpiarValores = () => {
		SetRegistro({
			..._Registro,
			txtNombre: '',
			txtApellido: '',
			txtNomCorto: '',
			txtPassword: '',
			numTelefono1: '',
			txtEmail: '',
			bolBloqueado: false,
			intIdArea: '',
			intIdSubarea: null,
			intIdEmpresa: null,
			intIdPlaza: '',
			intIdDepartamentos: '',
			intIdRegion: null,
			intIdPais: '',
		})
	}
	const [_ListaPlaza, setListaPlaza] = useState([])
	const [_ListaDepa, setListaDepa] = useState([])
	const [_ListaRegion, setListaRegion] = useState([])
	const [_ListaEmp, setListaEmp] = useState([])
	const [_ListaPai, setListaPai] = useState([])
	const [_ListaArea, setListaArea] = useState([])
	const [_ListaSubarea, setListaSubarea] = useState([])

	const getArea = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtArea/ObtenerLista')
			.then((response) => {
				setListaArea(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	const handleTextArea = (event) => {
		
		SetRegistro({ ..._Registro, [event.target.name]: event.target.value })
		requests
			.get('CtSubarea/ObtenerPorIdArea?id=' + event.target.value)
			.then((response) => {
				
				setListaSubarea(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	const getSubarea = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtSubarea/ObtenerLista')
			.then((response) => {
				setListaSubarea(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	const getRegion = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtRegion/ObtenerLista')
			.then((response) => {
				setListaRegion(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	const getplaza = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtPlaza/ObtenerLista')
			.then((response) => {
				setListaPlaza(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const getDepa = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtDepartamento/ObtenerLista')
			.then((response) => {
				setListaDepa(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	const getEmp = () => {
		props.setOpenLoadingScreen()
		requests
			.get('CtEmpresa/ObtenerLista')
			.then((response) => {
				setListaEmp(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	const getPai = () =>{
		props.setOpenLoadingScreen()
		requests
			.get('CtPais/ObtenerLista')
			.then((response) => {
				setListaPai(response)
				props.setCloseLoadingScreen()
			})
			.catch((error)=>{
				console.log('Error' + error)
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getplaza()
		getDepa()
		getEmp()
		getPai()
		getRegion()
		getArea()
		getSubarea()
	}, [])

	const handleText = (event) => {
		SetRegistro({ ..._Registro, [event.target.name]: event.target.value })
	}
	const guardar = () => {
		props.setOpenLoadingScreen()
		let url = 'TbUsuario/Crear'
		requests
			.post(url, _Registro)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				handleClose()
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				handleClose()
			})
	}

	return (
		<ThemeProvider theme={Theme}>
			<Dialog
				open={props.open}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				fullWidth
				maxWidth={'lg'}
			>
				<form onSubmit={handleSubmit(guardar)} autoComplete='off'>
					<div className='containerHeaderModal'>
						<DialogTitle
							id='alert-dialog-title'
							className='containerHeaderModal'
						>
							Agregar Usuario
						</DialogTitle>
						<div>
							<Button onClick={handleClose}>
								<CloseIcon
									fontSize='large'
									sx={{ color: 'rgba(43, 45, 66, 0.5)' }}
								/>
							</Button>
						</div>
					</div>

					<DialogContent sx={{ width: '100%', height: '250px' }}>
						<DialogContentText id='alert-dialog-description'>
							<Grid container
									direction='row'
									justifyContent='flex-start'
									alignItems='center'
									spacing={2}
							>
								<Grid item xs={4}>
									<TextField
										id='txtNombre'
										name='txtNombre'
										label='Nombre'
										variant='standard'
										fullWidth
										value={_Registro.txtNombre}
										onChange={handleText}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										id='txtApellido'
										name='txtApellido'
										label='Apellido'
										variant='standard'
										fullWidth
										value={_Registro.txtApellido}
										onChange={handleText}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										id='txtEmail'
										name='txtEmail'
										label='Email'
										type='email'
										variant='standard'
										fullWidth
										value={_Registro.txtEmail}
										onChange={handleText}
									/>
								</Grid>
								<Grid item xs={4}>
									<FormControl required variant='standard' fullWidth>
										<InputLabel id='intIdArea'>
											Listado de Areas
										</InputLabel>
										<Select
											required
											value={_Registro.intIdArea}
											onChange={handleTextArea}
											label='intIdArea'
											inputProps={{
												name: 'intIdArea',
												id: 'intIdArea',
											}}
										>
											<MenuItem aria-label='None' value='' />
											{_ListaArea.map((object, index) => (
												<MenuItem value={object.intIdArea}>
													{object.txtArea}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={4}>
									<FormControl required variant='standard' fullWidth>
										<InputLabel id='intIdSubarea'>
											Listado de Subareas
										</InputLabel>
										<Select
											required
											value={_Registro.intIdSubarea}
											onChange={handleText}
											label='intIdSubarea'
											inputProps={{
												name: 'intIdSubarea',
												id: 'intIdSubarea',
											}}
										>
											<MenuItem aria-label='None' value='' />
											{_ListaSubarea.map((object, index) => (
												<MenuItem value={object.intIdSubarea}>
													{object.txtSubarea}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={4}>
									<FormControl required variant='standard' fullWidth>
										<InputLabel id='intIdDepartamentos'>
											Listado de departamentos
										</InputLabel>
										<Select
											required
											value={_Registro.intIdDepartamentos}
											onChange={handleText}
											label='intIdDepartamentos'
											inputProps={{
												name: 'intIdDepartamentos',
												id: 'intIdDepartamentos',
											}}
										>
											<MenuItem aria-label='None' value='' />
											{_ListaDepa.map((object, index) => (
												<MenuItem value={object.intIdDepartamentos}>
													{object.txtDepartamento}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								{/* <Grid item xs={4}>
									<FormControl required variant='standard' fullWidth>
										<InputLabel id='intIdRegion'>
											Listado de Regiones
										</InputLabel>
										<Select
											required
											value={_Registro.intIdRegion}
											onChange={handleText}
											label='intIdRegion'
											inputProps={{
												name: 'intIdRegion',
												id: 'intIdRegion',
											}}
										>
											<MenuItem aria-label='None' value='' />
											{_ListaRegion.map((object, index) => (
												<MenuItem value={object.intIdRegion}>
													{object.txtRegion}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid> */}
								<Grid item xs={4}>
									<TextField
										id='numTelefono1'
										name='numTelefono1'
										label='Telefono'
										type='number'
										variant='standard'
										fullWidth
										value={_Registro.numTelefono1}
										onChange={handleText}
									/>
								</Grid>
								<Grid item xs={4}>
									<FormControl required variant='standard' fullWidth>
										<InputLabel id='intIdPlaza'>Listado de Plazas</InputLabel>
										<Select
											required
											value={_Registro.intIdPlaza}
											onChange={handleText}
											label='intIdPlaza'
											inputProps={{
												name: 'intIdPlaza',
												id: 'intIdPlaza',
											}}
										>
											<MenuItem aria-label='None' value='' />
											{_ListaPlaza.map((object, index) => (
												<MenuItem value={object.intIdPlaza}>
													{object.txtPlaza}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={4}>
									<FormControl required variant='standard' fullWidth>
										<InputLabel id='intIdPais'>Listado de Paises</InputLabel>
										<Select
											required
											value={_Registro.intIdPais}
											onChange={handleText}
											label='intIdPais'
											inputProps={{
												name: 'intIdPais',
												id: 'intIdPais',
											}}
										>
											<MenuItem aria-label='None' value='' />
											{_ListaPai.map((object, index) => (
												<MenuItem value={object.lngIdPais}>
													{object.txtPais}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								
							</Grid>
							<Grid container spacing={2} sx={{ mt: 1 }}>
								{/* <Grid item xs={4}>
									<FormControl required variant='standard' fullWidth>
										<InputLabel id='intIdEmpresa'>Listado de Empresas</InputLabel>
										<Select
											required
											value={_Registro.intIdEmpresa}
											onChange={handleText}
											label='intIdEmpresa'
											inputProps={{
												name: 'intIdEmpresa',
												id: 'intIdEmpresa',
											}}
										>
											<MenuItem aria-label='None' value='' />
											{_ListaEmp.map((object, index) => (
												<MenuItem value={object.intIdEmpresa}>
													{object.txtEmpresa}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid> */}
								
							</Grid>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} className='btn-close-modal'>
							Cerrar
						</Button>
						<Button
							className='btn-add-modal'
							variant='contained'
							type='submit'
							startIcon={<AddIcon />}
						>
							Agregar
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</ThemeProvider>
	)
}
export default UsuariosAdd
